import { render, staticRenderFns } from "./ServiceRequestDetails.vue?vue&type=template&id=517d1aff&scoped=true&"
import script from "./ServiceRequestDetails.vue?vue&type=script&lang=js&"
export * from "./ServiceRequestDetails.vue?vue&type=script&lang=js&"
import style0 from "./ServiceRequestDetails.vue?vue&type=style&index=0&id=517d1aff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517d1aff",
  null
  
)

export default component.exports